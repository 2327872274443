.school-card {
  &__link {
    height: 56px;
    display: block;

    @include media-breakpoint-up(sm) {
      height: 78px;
    }
    @include media-breakpoint-up(md) {
      height: 80px;
    }
  }

  &__container-img, &__image {
    height: 100%;
  }
}
