.stats {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__header {
    align-self: center;
    position: relative;
    @include media-breakpoint-up(md) {
      max-width: 316px;
    }
    @include media-breakpoint-up(lg) {
      max-width: 550px;
    }
  }

  &__title {
    @include title;
    margin-bottom: 0;

    > span {
      display: block;
      font-size: 92px;
      margin-bottom: 6px;
      @include media-breakpoint-up(lg) {
        font-size: 152px;
      }
    }

    > span > span {
      font-size: 52px;
      @include media-breakpoint-up(lg) {
        font-size: 64px;
      }
    }
  }

  &__star {
    color: $color-navy;
    position: absolute;
  }

  &__star-1 {
    left: 10px;
    top: -14px;
    width: 30px;
    @include media-breakpoint-up(sm) {
      left: 106px;
    }
    @include media-breakpoint-up(md) {
      left: 24px;
    }
    @include media-breakpoint-up(lg) {
      left: 90px;
    }
  }

  &__star-2 {
    top: 20px;
    left: 10px;
    width: 18px;
    @include media-breakpoint-up(sm) {
      left: 106px;
    }
    @include media-breakpoint-up(md) {
      left: 90px;
    }
    @include media-breakpoint-up(lg) {
      left: 90px;
      top: 24px;
    }
  }

  &__star-3 {
    right: 10px;
    top: -24px;
    width: 30px;
    @include media-breakpoint-up(sm) {
      right: 146px;
    }
    @include media-breakpoint-up(md) {
      right: 54px;
    }
    @include media-breakpoint-up(lg) {
      right: 160px;
    }
  }

  &__star-4 {
    top: 10px;
    right: 10px;
    width: 18px;
    @include media-breakpoint-up(sm) {
      right: 126px;
    }
    @include media-breakpoint-up(md) {
      right: 40px;
    }
    @include media-breakpoint-up(lg) {
      right: 140px;
    }
  }

  &__body {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 30px;
    @include media-breakpoint-up(lg) {
      gap: 158px;
    }
  }

  &__description {
    @include copy;
    text-align: center;
    @include media-breakpoint-up(md) {
      max-width: 474px;
    }
    @include media-breakpoint-up(lg) {
      max-width: 600px;
    }
  }

  &__list {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 48px;
    list-style: none;
    margin: 0;
    padding: 0;
    @include media-breakpoint-up(md) {
    }
    @include media-breakpoint-up(lg) {
      align-items: flex-start;
      gap: 0;
      width: 100%;
      max-width: 940px;
    }
    @include media-breakpoint-up(xl) {
      max-width: 100%;
    }
  }

  &__list-item {
    @include media-breakpoint-up(md) {
      max-width: 272px;
    }
    @include media-breakpoint-up(lg) {
      max-width: 444px;
    }

    &:nth-child(even) {
      @include media-breakpoint-up(lg) {
        align-self: flex-end;
      }
    }
  }
}
