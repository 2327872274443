.m {
  &--requests {}

  &--requests &__body {
  }

  &--requests &__body-container {
    @include media-breakpoint-up(lg) {
      margin: 0 auto;
      max-width: 786px;
    }
  }
}
