.footer {
  &__ellipse {
    color: $color-navy;
    display: block;
    width: 100%;
  }

  &__main {
    background: $color-navy;
    padding-bottom: 40px;
    padding-top: 50px;
    position: relative;
    @include media-breakpoint-up(md) {
    }
    @include media-breakpoint-up(lg) {
      padding-bottom: 60px;
      padding-top: 40px;
    }
  }

  &__container {
  }

  &__row-1 {
    align-items: center;
    display: flex;
    border-bottom: 1px solid $color-mint;
    flex-direction: column;
    gap: 46px;
    margin-bottom: 16px;
    padding-bottom: 32px;
    @include media-breakpoint-up(md) {
      margin-bottom: 20px;
      padding-bottom: 50px;
    }
    @include media-breakpoint-up(lg) {
      align-items: flex-start;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 12px;
      padding-bottom: 24px;
    }
  }

  &__row-1 > &__col-1 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 12px;
    @include media-breakpoint-up(md) {
      align-items: flex-start;
    }
  }

  &__row-1 > &__col-2 {
    @include media-breakpoint-up(lg) {
      flex-basis: 420px;
    }
  }

  &__branding {
    margin-left: 16px;
    @include media-breakpoint-up(md) {
      margin-left: 0;
    }
  }

  &__branding-link {}

  &__branding-small-logo {
    color: $color-white;
    width: 272px;
  }

  &__header {
    position: relative;
  }

  &__title {
    @include font-stack-josefin-sans-700;
    color: $color-white;
    font-size: 32px;
    line-height: 1;
    text-align: center;
    @include media-breakpoint-up(md) {
      font-size: 40px;
      text-align: left;
    }
  }

  &__star {
    color: $color-white;
    position: absolute;
  }

  &__star-1 {
    bottom: -14px;
    right: -20px;
    width: 28px;
    @include media-breakpoint-up(md) {
      right: -40px;
      bottom: 16px;
    }
  }

  &__star-2 {
    bottom: -30px;
    right: -5px;
    width: 16px;
    @include media-breakpoint-up(md) {
      right: -20px;
      bottom: 0px;
    }
  }

  &__nav {
  }

  &__nav-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
    max-width: 500px;
    @include media-breakpoint-up(md) {
      gap: 32px;
    }
  }

  &__nav-list-item {
    flex-basis: 100%;
    text-align: center;
    @include media-breakpoint-up(sm) {
      flex-basis: auto;
    }

    &:nth-child(3) {
      display: none;
    }

    &:last-child {
      margin-top: 16px;
      @include media-breakpoint-up(md) {
        flex-basis: 100%;
        margin-top: 0;
      }
    }
  }

  &__nav-list-item-link {
    @include font-stack-work-sans;
    color: $color-white;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: .2px;

    &:hover {
      text-decoration: underline;
    }
  }

  &__nav-list-item-button {
    display: block;
    width: 100%;
  }

  &__row-2 {

  }

  &__copy {
    @include font-stack-work-sans;
    color: $color-white;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
  }
}
