.faq {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-top: 120px;

  &__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;

    @include media-breakpoint-up(lg) {
      flex-flow: row;
      justify-content: center;
    }
  }

  &__star {
    color: $color-navy;
    height: 1.1em;
    width: 1.1em;
    @include media-breakpoint-up(lg) {
      width: 1.9em;
      height: 1.9em;
    }
  }

  &__draw {
    display: none;

    @include media-breakpoint-up(lg) {
      width: 50%;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
    }

    &-stars-group, &-arrows-group {
      display: flex;
      flex-flow: column;
      width: 100%;
      align-items: center;
      margin-bottom: 30px;
    }

    &-star-1, &-star-2, &-star-3 {
      position: relative;
    }

    &-star-1 {
      width: 3.1em;
      height: 3.1em;
    }

    &-star-2 {
      left: 50px;
    }

    &-star-3 {
      top: 15px;
    }

    &-arrow {
      position: relative;
      transform: rotate(180deg);
      left: 25px;
      bottom: -15px;
      width: 40px;
      color: $color-navy;
    }

    &-large-arrow {
      color: $color-navy;
    }

    &-school-group {
      position: relative;
    }

    &-star-4 {
      width: 30px;
      height: 30px;
      position: absolute;
      left: -70px;

      @include media-breakpoint-up(lg) {
        width: 3.1em;
        height: 3.1em;
      }
    }

    &-star-5 {
      position: absolute;
      top: 50px;
      left: -20px;
    }
  }

  &__header {
    display: flex;
    flex-flow: column;
    align-items: center;

    @include media-breakpoint-up(lg) {
      align-items: flex-start;
    }

    &-star-6, &-star-7, &-star-8 {
      position: absolute;
    }

    &-star-6 {
      top: 0;
      right: -20px;

      @include media-breakpoint-up(lg) {
        right: -30px;
      }
    }

    &-star-7 {
      width: 30px;
      height: 30px;
      top: 30px;
      right: -60px;

      @include media-breakpoint-up(lg) {
        width: 3.1em;
        height: 3.1em;
        right: -80px;
      }
    }

    &-star-8 {
      top: 20px;
      right: -90px;

      @include media-breakpoint-up(lg) {
        right: -110px;
      }
    }
  }

  &__title {
    @include font-stack-josefin-sans;
    text-align: center;
    font-size: 40px;
    max-width: 272px;
    line-height: 100%;
    color: $color-navy;
    margin: 20px 0 30px 0;
    font-weight: 700;

    @include media-breakpoint-up(sm) {
      max-width: 328px;
    }

    @include media-breakpoint-up(lg) {
      max-width: 390px;
      text-align: left;
      font-size: 48px;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 85px;
    height: 90px;

    @include media-breakpoint-up(lg) {
      width: 152px;
      height: 160px;
    }

    &-ellipse {
      width: 100%;
      height: 100%;
      transform: rotate(-90deg);
    }

    &-text {
      @include title-with-shadow;
      color: $color-white;
      font-size: 72px;
      font-weight: 700;
      position: absolute;
      z-index: $zi-ground + 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: inline;
      text-align: center;
      margin-top: 20px;
    }
  }

  &__draw-supplies path {
    color: $color-navy;
  }
}
