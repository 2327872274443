:root{
  --side-padding: 12px;
  @media (min-width: 576px){
    --side-padding: 40px;
  }
}

body {
  @include font-stack-work-sans;

  * {
    @include media-breakpoint-down(sm) {
      --bs-gutter-x: 3rem !important;
    }
  }
}

.container-fluid {
  padding-left: var(--side-padding) !important;
  padding-right: var(--side-padding) !important;
  max-width: $container-max-width;
  &.wide{
    max-width: $container-max-width-lg;
  }
}

a {
  color: inherit;
  text-decoration: none;
  transition: color .4s ease, background-color .4s ease;

  &:active,
  &:hover {
    outline: none;
  }
}

strong {
  font-weight: bold;
}

main {
  margin-top: -100px;
  padding-top: 100px;
  @include media-breakpoint-up(md) {
  }
  @include media-breakpoint-up(xl) {
    margin-top: -124px;
    padding-top: 124px;
  }

  > div {
    @include vertical-space;
  }
}

[id] {
  scroll-margin-top: calc(100px + 60px);
  @include media-breakpoint-up(xl) {
    scroll-margin-top: calc(124px + 60px);
  }
}
