.hero {
  &__container {
    display: flex;
    flex-direction: column;
    padding: 40px 0;
    @include media-breakpoint-up(xl) {
      flex-direction: row;
      padding: 100px 0 80px 0;
    }
  }

  &__body,
  &__container-image {
    flex: 1;
  }

  &__body {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-up(xl) {
      align-items: flex-start;
      text-align: left;
    }
  }

  &__container-image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 90px;

    @include media-breakpoint-up(xl) {
      margin-top: 0;
    }
  }

  &__image-content {
    position: relative;
    display: flex;
    justify-content: center;
  }

  &__title {
    @include font-stack-josefin-sans-700;
    color: $color-navy;
    font-size: 40px;
    line-height: 100%;
    margin-bottom: 12px;
    @include media-breakpoint-up(lg) {
      font-size: 64px;
      margin-bottom: 18px;
      max-width: 540px;
    }
  }

  &__description {
    @include copy;
    margin-bottom: 20px;
    max-width: 522px;
    @include media-breakpoint-up(xl) {
      margin-bottom: 46px;
    }
  }

  &__cta {
    padding-left: 40px;
    padding-right: 40px;
  }

  &__bg-shape {
    transform: rotate(140deg);
    position: absolute;
    left: 40px;
    top: -25px;
    height: 100px;
    z-index: $zi-below-ground;

    @include media-breakpoint-up(sm) {
      left: 70px;
      top: -35px;
      height: 120px;
    }

    @include media-breakpoint-up(xl) {
      top: -50px;
      height: 160px;
    }
  }

  &__image {
    width: 420px;
    max-width: 80%;

    @include media-breakpoint-up(xl) {
      max-width: 100%;
    }
  }

  &__large-arrow {
    position: absolute;
    color: $color-navy;
  }

  &__large-arrow-1 {
    left: -60px;
    top: 0;
    height: 85px;

    @include media-breakpoint-up(sm) {
      height: 140px;
    }

    @include media-breakpoint-up(xl) {
      left: -100px;
      height: 180px;
    }
  }

  &__large-arrow-2 {
    right: -10px;
    top: 20px;
    height: 70px;

    @include media-breakpoint-up(sm) {
      right: -45px;
      height: 120px;
    }
  }

  &__star {
    position: absolute;
    color: $color-navy;
  }

  &__star-1 {
    right: 70px;
    top: -15px;
    height: 15px;

    @include media-breakpoint-up(sm) {
      height: 40px;
      top: -30px;
    }

    @include media-breakpoint-up(xl) {
      right: 80px;
      top: -20px;
    }
  }

  &__video-container {
    position: absolute;
    bottom: -70px;
    left: 50px;

    @include media-breakpoint-up(xl) {
      bottom: -50px;
      left: 0;
    }
  }

  &__video-button {
    width: 70px;
    height: 70px;
    border-radius: 70px;
    background: $color-orange;
    border: none;
    box-shadow: 6px 0px 0px 0px rgba(255, 107, 0, 0.33);
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-up(sm) {
      width: 90px;
      height: 90px;
      border-radius: 90px;
    }

    @include media-breakpoint-up(xl) {
      width: 120px;
      height: 120px;
      border-radius: 120px;
    }
  }

  &__video-polygon {
    color: $color-white;
    width: 30px;
    height: 30px;

    @include media-breakpoint-up(xl) {
      width: 40px;
      height: 40px;
    }
  }

  &__video-text {
    @include font-stack-josefin-sans-700;
    color: $color-navy;
    font-size: 20px;
    text-align: center;
  }

  &__star-2 {
    width: 14px;
    bottom: 50px;
    right: -20px;

    @include media-breakpoint-up(sm) {
      width: 20px;
      bottom: 30px;
      right: -20px;
    }
  }

  &__star-3 {
    width: 30px;
    bottom: 10px;
    right: -50px;
  }

  &__arrow {
    width: 35px;
    position: absolute;
    color: $color-navy;
    bottom: -5px;
    left: -35px;
  }
}
