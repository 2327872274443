.stat-card {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
  @include media-breakpoint-up(lg) {
    align-items: flex-start;
  }

  &__description {
    @include font-stack-work-sans;
    color: $color-navy;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.2;
    @include media-breakpoint-up(lg) {
      text-align: left;
    }
  }

  &__circle {}

  &--extra-dark-blue &__circle {
    padding-top: 26px;
  }

  &--extra-light-purple &__circle {
  }

  &--orange &__circle {
    padding-top: 32px;
  }

  &__circle-container {
    height: 112px;
    padding: 20px 0 20px 10px;
    position: relative;
    width: 124px;
    @include media-breakpoint-up(lg) {
      height: 209px;
      padding: 36px 0 0 0;
      width: 230px;
    }
  }

  &--1 &__circle-container {
    @include media-breakpoint-up(lg) {
      margin-left: -10px;
    }
  }
  &--3 &__circle-container {
    @include media-breakpoint-up(lg) {
      height: 245px;
      padding: 36px 0 0 0;
      width: 270px;
      margin-left: -30px;
    }
  }

  &__ellipse {
    display: block;
    inset: 0;
    position: absolute;
    width: 100%;
    z-index: $zi-ground;
  }

  &--extra-dark-blue &__ellipse {
    transform: rotate(-20deg);
  }

  &--extra-light-purple &__ellipse {
    transform: rotate(-10deg);
  }

  &--orange &__ellipse {}

  &__stat {
    @include title-with-shadow;
    position: relative;
    z-index: $zi-ground + 1;
  }

  &--1 &__stat {}

  &--2 &__stat {
    font-size: 40px;
    line-height: 72px;
    @include media-breakpoint-up(lg) {
      font-size: 64px;
      line-height: normal;
      margin-top: 18px;
      margin-left: 34px;
      text-align: left;
    }

    > span {
      display: none;
      @include media-breakpoint-up(lg) {
        display: block;
        font-size: 24px;
        line-height: normal;
      }
    }
  }

  &--3 &__stat {
    text-shadow: 4px 6px 0 $color-navy;
    @include media-breakpoint-up(lg) {
      font-size: 130px;
      margin-top: 30px;
      letter-spacing: -4px;
    }
  }

  &__school {
    color: $color-navy;
    position: absolute;
    right: -64px;
    top: -26px;
    @include media-breakpoint-up(lg) {
      width: 250px;
      right: -230px;
      top: -26px;
    }
  }

  &__backpack {
    color: $color-navy;
    position: absolute;
    right: -70px;
    top: 0;
    width: 66px;

    @include media-breakpoint-up(lg) {
      right: -130px;
      top: -24px;
      width: 140px;
    }
  }

  &__book {
    color: $color-navy;
    position: absolute;
    right: -60px;
    top: -32px;
    @include media-breakpoint-up(md) {
      top: -42px;
    }
    @include media-breakpoint-up(lg) {
      right: -220px;
      top: 10px;
      width: 222px;
    }
  }

  &__large-arrow-2 {
    color: $color-navy;
    transform: rotate(26deg);
    width: 80px;
    @include media-breakpoint-up(lg) {
      left: 550px;
      position: absolute;
      top: 150px;
      transform: rotate(-30deg);
      width: 140px;
    }
    @include media-breakpoint-up(xl) {
      left: 550px;
    }
  }

  &__large-arrow-3 {
    color: $color-navy;
    width: 114px;
    @include media-breakpoint-up(lg) {
      left: -280px;
      position: absolute;
      top: 100px;
      transform: rotate(16deg);
      width: 230px;
    }
    @include media-breakpoint-up(xl) {
      left: -360px;
    }
  }
}
