.header {
  left: 0;
  padding: 16px 0;
  position: sticky;
  right: 0;
  top: -1px;
  transition: box-shadow .4s ease;
  z-index: $zi-low;
  @include media-breakpoint-up(md) {
  }
  @include media-breakpoint-up(xl) {
  }

  &--open {
    @include media-breakpoint-down(xl) {
      background: $color-gray;
      height: calc(var(--vh, 1vh) * 100);
      inset: 0;
      overflow-y: auto;
      position: fixed;
      z-index: $zi-low;
    }
  }

  &--stuck {
    background: $color-white;
    box-shadow: 0 4px 2px -2px $color-gray;
    @include media-breakpoint-up(md) {
      padding: 20px 0;
    }
  }

  &__container {
    position: relative;
  }

  &__belt {
    display: flex;
    justify-content: space-between;
  }

  &--open &__belt {
    @include media-breakpoint-down(md) {
      margin-bottom: 40px;
    }
    @include media-breakpoint-only(md) {
      margin-bottom: 96px;
    }
  }

  &__branding {
    align-items: center;
    display: flex;
    gap: 8px;
    @include media-breakpoint-up(md) {
      gap: 16px;
    }
  }

  &--open &__branding {
    @include media-breakpoint-up(md) {
      gap: 30px;
    }
  }

  &__branding-link {
    border-left: 1px solid $color-white;
    padding-left: 8px;
    @include media-breakpoint-up(md) {
      padding-left: 16px;
    }

    &:first-child {
      border-left: none;
      padding-left: 0;
    }
  }

  &__logo {
    @include media-breakpoint-up(xl) {
      height: 92px;
      width: auto;
    }
  }

  &__menu {
    position: relative;
    right: -11px;
    top: 10px;
    @include media-breakpoint-up(xl) {
      display: none;
    }
  }

  &__menu-button {
    align-items: center;
    display: flex;
    background: none;
    border: none;
    height: 44px;
    justify-content: center;
    margin: 0;
    padding: 0;
    width: 44px;
  }

  &__menu-burger {
    color: $color-purple;
  }

  &__menu-close {
    color: $color-navy;
    width: 22px;
  }

  &__body {
    @include media-breakpoint-down(xl) {
      display: none;
    }
    @include media-breakpoint-up(xl) {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  &--open &__body {
    @include media-breakpoint-down(xl) {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin: 0 auto;
      max-width: 310px;
      min-height: calc((var(--vh, 1vh) * 100) - 140px);
    }
    @include media-breakpoint-only(md) {
      min-height: calc((var(--vh, 1vh) * 100) - 196px);
    }
  }

  &__nav {}

  &--open &__nav {
    @include media-breakpoint-down(xl) {
      margin-bottom: auto;
    }
  }

  &__nav-list {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 32px;
    list-style: none;
    margin: 0;
    padding: 0;
    @include media-breakpoint-up(md) {
      gap: 48px;
    }
    @include media-breakpoint-up(xl) {
      flex-direction: row;
      gap: 40px;
    }
  }

  &--open &__nav-list {
    @include media-breakpoint-down(xl) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &__nav-list-item {
    &:last-child {
      @include media-breakpoint-up(xl) {
        display: none;
      }
    }
  }

  &--open &__nav-list-item {
    @include media-breakpoint-down(xl) {
      flex-basis: 100%;
      text-align: center;
    }
  }

  &__nav-list-item-link {
    @include font-stack-work-sans;
    color: $color-purple;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: .2px;
    line-height: 18px;
    @include media-breakpoint-up(md) {
    }
    @include media-breakpoint-up(xl) {
      color: $color-dark;
      font-size: 14px;
    }

    &:hover {
      color: $color-dark-purple;
    }
  }

  &__nav-list-item-button {
    @include media-breakpoint-down(lg) {
      display: block;
      width: 100%;
    }
    @include media-breakpoint-up(xl) {
      box-shadow: none;
      font-size: 16px;
      padding: 8px 24px;
    }
  }
}
