@mixin font-stack-work-sans {
  font-family: 'Work Sans', sans-serif;
}

@mixin font-stack-josefin-sans {
  font-family: 'Josefin Sans', sans-serif;
}

@mixin font-stack-josefin-sans-700 {
  @include font-stack-josefin-sans;
  font-weight: 700;
}

@mixin title {
  @include font-stack-josefin-sans-700;
  color: $color-navy;
  text-align: center;
  font-size: 40px;
  line-height: 1;
  @include media-breakpoint-up(lg) {
    font-size: 64px;
  }
}

@mixin title-with-shadow {
  @include font-stack-josefin-sans-700;
  color: $color-white;
  font-size: 72px;
  line-height: 1;
  text-align: center;
  text-shadow: 2px 4px 0 $color-navy;
  @include media-breakpoint-up(lg) {
    font-size: 152px;
  }

  > span {
    font-size: 32px;
    @include media-breakpoint-up(lg) {
      font-size: 64px;
    }
  }
}

@mixin subtitle {
  @include font-stack-work-sans;
  color: $color-navy;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
}

@mixin copy {
  @include font-stack-work-sans;
  color: $color-navy;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  @include media-breakpoint-up(xl) {
    font-size: 18px;
    line-height: 1.2;
  }

  >  p {
    @include font-stack-work-sans;
    color: $color-navy;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 20px;
    @include media-breakpoint-up(xl) {
      font-size: 18px;
      line-height: 1.2;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@mixin vertical-space {
  margin-bottom: 64px;
}
