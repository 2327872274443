.content {
  &__ellipse {
    color: $color-green;
    display: block;
    width: 100%;
  }

  &__main {
    background: $color-green;
    padding-top: 60px;
    padding-bottom: 30px;
    position: relative;
    @include media-breakpoint-up(md) {
      padding-top: 30px;
      padding-bottom: 60px;
    }
    @include media-breakpoint-up(lg) {
      padding-top: 20px;
      padding-bottom: 60px;
    }
  }

  &__container {
  }
}
