.resource-card {
  &__link {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 243px;
    justify-content: center;
    padding: 20px 10px 0 10px;
    position: relative;
    width: 270px;
  }

  &__circle {
    display: block;
    inset: 0;
    position: absolute;
    width: 100%;
    z-index: $zi-below-ground;
  }

  &__title {
    color: $color-navy;
    @include font-stack-work-sans;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.2;
  }

  &__cta {
    @include font-stack-work-sans;
    color: $color-dark-blue;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.2;
    text-decoration-line: underline;
  }
}
