@charset "UTF-8";

// 1. Configuration and helpers
@import
'abstracts/variables',
'abstracts/functions',
'abstracts/keyframes',
'abstracts/mixins';

// 2. Vendors
@import
'vendors/bootstrap';

// 3. Base stuff
@import
'base/base',
'base/fonts',
'base/typography',
'base/helpers';

// 4. Layout-related sections

// 5. Components
@import
'../../components/Accordion/styles',
'../../components/Icons/styles',
'../../components/Button/styles',
'../../components/Modal/styles',
'../../components/Form/styles',
'../../components/Layout/styles',
'../../components/Header/styles',
'../../components/Hero/styles',
'../../components/Content/styles',
'../../components/Stats/styles',
'../../components/StatCard/styles',
'../../components/Resources/styles',
'../../components/ResourceCard/styles',
'../../components/SchoolCard/styles',
'../../components/Schools/styles',
'../../components/FAQ/styles',
'../../components/Footer/styles',
'../../components/RequestsModal/styles',
'../../components/VideoModal/styles';

// 6. Page-specific styles

// 7. Themes
