.schools {
  @include media-breakpoint-up(xl) {
    margin-bottom: 128px;
  }
  &__container {
    display: flex;
    flex-direction: column;
    gap: 60px;
  }

  &__header {
    align-self: center;
    max-width: 330px;
    padding-top: 40px;
    position: relative;

    @include media-breakpoint-up(md) {
      max-width: none;
    }
  }

  &__arrow {
    bottom: -30px;
    color: $color-navy;
    position: absolute;
    right: 0;
    transform: rotate(-120deg);
    width: 30px;
    @include media-breakpoint-up(md) {
      right: -40px;
      width: 40px;
    }
    @include media-breakpoint-up(lg) {
      bottom: 0px;
      right: -45px;
      transform: rotate(-140deg);
    }
  }

  &__star {
    color: $color-navy;
    position: absolute;
  }

  &__star-1 {
    top: 0px;
    left: 18px;
    width: 28px;
    @include media-breakpoint-up(md) {
      top: 0px;
      left: -18px;
    }
  }

  &__star-2 {
    top: 16px;
    left: 5px;
    width: 16px;
    @include media-breakpoint-up(md) {
      left: -30px;
      top: 16px;
    }
  }

  &__title {
    @include title;
    margin-bottom: 0;
  }

  &__drawing {
    color: $color-navy;
    position: absolute;
    right: -40px;
    top: -18px;
    transform: rotate(-15deg);
    width: 110px;
    @include media-breakpoint-up(md) {
      right: -66px;
      top: -14px;
      width: 134px;
    }
    @include media-breakpoint-up(lg) {
      right: -96px;
      top: -36px;
      width: 184px;
    }
  }

  &__body {
  }

  &__list {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    gap: 48px 16px;
    list-style: none;
    margin: 0;
    padding: 0;
    @include media-breakpoint-up(sm) {
      gap: 48px 36px;
    }
    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: center;
    }
  }
}
