.m {
  &--video {}

  &--video &__body {
    padding-bottom: 0;
    padding-top: 0;
  }

  &--video &__body-container {
    align-items: center;
    display: flex;
    margin: 0 auto;
    max-width: 1086px;
    min-height: calc(var(--vh, 1vh) * 100);
  }

  .video {
    height: 0;
    overflow: hidden;
    padding-bottom: calc((9 / 16) * 100%);
    position: relative;
    width: 100%;

    &__iframe {
      bottom: 0;
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
    }
  }

  &__iframe-container {
    overflow: hidden;
    position: relative;
    width: 100%;
    padding-bottom: calc((9 / 16) * 100%);
  }

  &__iframe {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
}
