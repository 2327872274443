.form {
  &__container {}

  &__item {
    margin-bottom: 16px;
    @include media-breakpoint-up(md) {
      margin-bottom: 24px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item--title {
    margin-bottom: 50px;
  }

  &__item--2-cols {
    @include media-breakpoint-up(md) {
      display: flex;
      gap: 20px;
    }
  }

  &__item--2-cols &__item {
    @include media-breakpoint-up(md) {
      flex-basis: 50%;
      margin-bottom: 0;
    }
  }

  &__item--checkbox {
  }

  &__title {
    @include font-stack-josefin-sans-700;
    color: $color-navy;
    font-size: 32px;
    line-height: 1.2;
    text-align: center;
    @include media-breakpoint-up(lg) {
      font-size: 40px;
    }
  }

  &__label {
    @include font-stack-work-sans;
    color: $color-black;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
  }

  &__item--checkbox &__label {
    margin-bottom: 16px;
  }

  &__checkbox-container &__label {
    margin-bottom: 0;
  }

  &__required {
    color: $color-red;
  }

  &__control-container {
    position: relative;
  }

  &__checkbox-container {
    display: block;
  }

  &__checkbox-label {
    align-items: center;
    display: flex;
    gap: 8px;
  }

  &__email,
  &__password,
  &__text,
  &__textarea {
    @include font-stack-work-sans;
    border: 1px solid $color-black;
    color: $color-dark-gray;
    display: block;
    font-size: 16px;
    line-height: 20px;
    outline: none;
    padding: 12px;
    width: 100%;

    &::placeholder {
      color: $color-dark-gray;
      opacity: 1;
    }
  }

  &__item--with-error &__email,
  &__item--with-error &__password,
  &__item--with-error &__text {
    border-color: $color-red;
  }

  &__error {
    @include font-stack-work-sans;
    align-items: center;
    color: $color-red;
    display: flex;
    font-size: 14px;
    line-height: 18px;
    margin-top: 4px;
  }

  &__help {
    > p {
      @include font-stack-work-sans;
      color: $color-black;
      font-size: 14px;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__submit {
    margin-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
  }
}
