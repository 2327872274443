.button {
  @include font-stack-josefin-sans-700;
  background: none;
  border: 1px solid $color-white;
  border-radius: 36px;
  color: $color-white;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  margin: 0;
  padding: 16px;
  text-align: center;
  transition: background-color .4s ease, border-color .4s ease, color .4s ease;
  @include media-breakpoint-up(md) {
  }
  @include media-breakpoint-up(lg) {
    font-size: 22px;
  }

  &:hover {
    background: $color-white;
    color: $color-dark;
  }

  &:active {
    background: $color-gray;
    color: $color-dark;
  }

  &[disabled] {
    cursor: not-allowed;

    &::after {
      content: none;
    }
  }

  &--with-icon {
    @include media-breakpoint-up(md) {
      justify-content: space-between;
    }
  }

  &__icon-spinner {
    margin-right: 12px;
  }

  &--mint {
    background: $color-mint;
    border: 1px solid $color-mint;
    box-shadow: 0 6px 0 0 rgba($color-light-mint, .6);
    color: $color-navy;

    &:active,
    &:hover {
      background: $color-dark-mint;
      color: $color-navy;
    }
  }

  &--navy {
    background: $color-navy;
    border: 1px solid $color-navy;
    box-shadow: 0 6px 0 0 rgba($color-blue, .33);

    &:active,
    &:hover {
      background: $color-dark-purple;
      color: $color-white;
    }
  }

  &--orange {
    background: $color-orange;
    border: 1px solid $color-orange;
    box-shadow: 0 6px 0 0 rgba($color-light-orange, .33);

    &:active,
    &:hover {
      background: $color-dark-orange;
      color: $color-white;
    }
  }

  &--purple {
    background: $color-purple;
    border: 1px solid $color-purple;
    box-shadow: 0 6px 0 0 rgba($color-light-purple, .15);

    &:active,
    &:hover {
      background: $color-dark-purple;
      color: $color-white;
    }
  }

  &--accordion {
    @include font-stack-work-sans;
    align-items: center;
    background-color: transparent;
    border: none;
    color: $color-navy;
    display: flex;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    text-align: left;
    width: 100%;

    &:active,
    &:hover {
      background-color: transparent;
      color: $color-navy;
    }

    @include media-breakpoint-up(sm) {
      font-size: 24px;
    }
  }
}
