.icon-arrow { width: 49px; }
.icon-drawing { width: 206px; }
.icon-burger { width: 22px; }
.icon-circle { width: 273px; }
.icon-circle--yellow { color: $color-yellow; }
.icon-circle--blue { color: $color-light-blue; }
.icon-circle--green { color: $color-light-green; }
.icon-ellipse { width: 139px; }
.icon-ellipse--dark-blue { color: $color-dark-blue; }
.icon-ellipse--extra-dark-blue { color: $color-extra-dark-blue; }
.icon-ellipse--extra-light-purple { color: $color-extra-light-purple; }
.icon-ellipse--orange { color: $color-orange; }
.icon-ellipse--green { color: $color-green; }
.icon-ellipse--dark-green { color: $color-dark-green; }
.icon-chevron { width: 17px; }
.icon-close { width: 25px; }
.icon-logo { width: 115px; }
.icon-large-arrow-1 { width: 185px; }
.icon-large-arrow-2 { width: 100px; }
.icon-large-arrow-3 { width: 126px; }
.icon-large-arrow-4 { width: 378px; }
.icon-polygon { width: 40px; }
.icon-school-supplies { width: 256px; }
.icon-small-logo { width: 305px; }
.icon-spinner {
  animation: rotate 1.8s linear infinite;
  width: 20px;

  > circle {
    animation: spin 1.2s ease-in-out infinite
  }
}
.icon-star { width: 28px; }
.icon-top-ellipse { width: 1440px; }
.icon-bottom-ellipse { width: 1440px; }
.icon-backpack { width: 139px; }
.icon-school { width: 105px; }
.icon-book { width: 120px; }
