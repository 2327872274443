.accordion {
  @include font-stack-work-sans;
  background-color: rgba($color-white, .5);
  border-radius: 24px;
  color: $color-navy;
  font-weight: 500;
  margin-bottom: 24px;
  max-width: 540px;
  transition: background-color .25s ease-in-out;
  width: 100%;

  &--open {
    background-color: $color-white;
    box-shadow: 0 4px 12px 0 rgba($color-navy, .15);
  }

  &__collapse {
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.25s ease-in-out;
  }

  &--open .accordion__collapse {
    max-height: 700px;
  }

  &__chevron {
    min-width: 17px;
    transition: transform 0.20s ease-in-out;
  }

  &--open .accordion__chevron {
    transform: rotate(180deg);
  }

  &__chevron {
    color: $color-navy;
    margin-right: 20px;
  }

  &__body {
    font-size: 14px;
    line-height: 18px;
    padding: 0 20px 20px 20px;

    > a {
      color: $color-dark-blue;
      text-decoration: underline;
    }
  }
}


