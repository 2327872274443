.m {
  &--open {
    overflow: hidden;
  }

  &__overlay {
    inset: 0;
    position: fixed;
    z-index: $zi-highest;
  }

  &__content {
    background: $color-white;
    inset: 0;
    overflow: auto;
    outline: none;
    position: absolute;
  }

  &--dark &__content {
    background: rgba($color-black, .8);
  }

  &__close {
    align-items: center;
    background: none;
    border: none;
    display: flex;
    height: 44px;
    justify-content: center;
    min-width: 0;
    padding: 0;
    position: absolute;
    right: 20px;
    top: 20px;
    width: 44px;
    z-index: $zi-lowest;
  }

  &__close-icon {
    color: $color-black;
    width: 30px;
  }

  &--dark &__close-icon {
    color: $color-white;
  }

  &__body {
    padding-bottom: 40px;
    padding-top: 84px;
    position: relative;
    z-index: $zi-ground;
  }

  &__body-container {

  }
}
