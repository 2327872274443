$zi-highest: 50;
$zi-high: 40;
$zi-medium: 30;
$zi-low: 20;
$zi-lowest: 10;
$zi-ground: 0;
$zi-below-ground: -1;

$color-black: #000;
$color-white: #fff;

$color-dark: #373F41;
$color-navy: #09255A;

$color-gray: #EFF4FF;
$color-dark-gray: #86949F;

$color-purple: #9E00E8;
$color-extra-light-purple: #A76BE2;
$color-light-purple: #9F00E8;
$color-dark-purple: #5E0389;

$color-light-blue: #77EFFF;
$color-blue: #7CA4F5;
$color-dark-blue: #003DB0;
$color-extra-dark-blue: #3B67D7;

$color-mint: #7CF5E0;
$color-light-mint: #7CF5E1;
$color-dark-mint: #53E3CA;

$color-yellow: #F2D25E;

$color-light-green: #66FAA8;
$color-green: #73ECD7;
$color-dark-green: #4DD78A;

$color-orange: #E86100;
$color-light-orange: #FF6A00;
$color-dark-orange: #C73D02;

$color-red: #E84545;

$container-max-width: calc(1110px + var(--side-padding)*2);
$container-max-width-lg: calc(1160px + var(--side-padding)*2);
